import { GET_ALL_USER_NOTIFICATIONS } from "./notificationTypes";

const initialState = {
  user: JSON.parse(localStorage.getItem("userData")),
  userallNotifications: [],
  totalunreads: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_NOTIFICATIONS:
      return {
        ...state,
        userallNotifications: action.payload.data,
        totalunreads: action.payload.total_unread
      };
    default:
      return state;
  }
};

export default notificationReducer;
