import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./reduxdata/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import '@fortawesome/fontawesome-free/css/all.min.css';
console.error = console.warn = () => {};
const LoadingSpinner = React.lazy(() => import("./LoadingSpinner"));
const LazyApp = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));

const CLIENT_ID = "691895213501-ee0lk258nt1d05oou0muo33hjvbucu30.apps.googleusercontent.com";
// const CLIENT_ID = "248026940485-nc5efqenmt7trcndskto6uv460dk6dkq.apps.googleusercontent.com";
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Suspense fallback={<LoadingSpinner />}>
          <LazyApp />
        </Suspense>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
