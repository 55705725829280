import { GET_PROFILE_DATA, USER_UPDATE, GET_USER_PROFILE, LOGOUT, IS_SUBMIT, GET_USER_BIDS_INFO, GET_URL_PATH, IS_EDIT } from "./userTypes";
const initialState = {
  user: JSON.parse(localStorage.getItem("userData")),
  // token: JSON.parse(localStorage.getItem("token")),
  userProfile: [],
  userDetails: [],
  isSubmit: false,
  bidsdata: [],
  urlPath: null,
  isedit: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        user: null,
        // token:null,
        userProfile: [],
        userDetails: []
      };
    case GET_PROFILE_DATA:
      return {
        ...state,
        userProfile: action.payload,
      };
    case USER_UPDATE:
      return {
        ...state,
        user: action.payload,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        userDetails: action.payload,
      };
    case IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case GET_USER_BIDS_INFO:
      return {
        ...state,
        bidsdata: action.payload,
        totalbidWons: action.payload.bidWons,
      };
    case GET_URL_PATH:
      return {
        ...state,
        urlPath: action.payload,
      };
    case IS_EDIT:
      return {
        ...state,
        isedit: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
