import { combineReducers } from "redux";
import userReducer from "./Users/userReducer";
import { auctionReducer } from "./Auctions/auctionReducer";
import loaderReducer from "./Loader/loaderReducer";
import notificationReducer from "./Notifications/notificationReducer";
import cmsReducer from "./cms/cmsReducer";
const rootReducer = combineReducers({
    auth: userReducer,
    games: auctionReducer,
    loader: loaderReducer,
    notifications: notificationReducer,
    cms: cmsReducer,
});

export default rootReducer;
